import {DecodedToken} from './decoded-token';

export class UserRepresentation {
  firstName: string;
  lastName: string;
  dni: string;
  lang: string;
  idPacient: number;

  constructor(token: DecodedToken) {
    this.firstName = token.given_name;
    this.lastName = token.family_name;
    this.dni = token.dni;
    this.lang = token.idioma;
  }
}
