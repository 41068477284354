import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageInfoService {
  private _currentLanguage: string;
  private _availableLanguages: string[];

  getCurrentLanguage(): string {
    return this._currentLanguage;
  }

  getAvailableLanguages(): string[] {
    return this._availableLanguages;
  }

  setCurrentLanguage(lang: string) {
    this._currentLanguage = lang;
  }

  setAvailableLanguages(langs: string[]) {
    this._availableLanguages = langs;
  }
}
