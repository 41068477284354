import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from "./authentication/guards/auth.guard";
import {AppComponent} from './app.component';
import {PageSessionExpiredComponent} from './authentication/components/page-session-expired.component/page-session-expired.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./certificate/certificate.module').then(m => m.CertificateModule)
      }
    ]
  },
  {
    path: 'session-expired',
    component: PageSessionExpiredComponent
  }
]; // sets up routes constant where you define your routes

// configures NgModule imports and exports
@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
