import {Injectable} from '@angular/core';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {LanguageService} from '../../language/services/language.service';

@Injectable({
  providedIn: 'root'
})
export class PaginatorI18nService {
  constructor(private readonly translate: TranslateService,
              private _languageService: LanguageService) {}

  getPaginatorIntl(): Observable<MatPaginatorIntl> {
    return this._languageService.getCurrentLanguage().pipe(
      switchMap((lang: string) => {
        const paginatorIntl = new MatPaginatorIntl();
        return this.translate.get(['PAGINATOR.ITEMS_PER_PAGE', 'PAGINATOR.RANGE_INTRODUCTION_WORD', 'PAGINATOR.RANGE_WORD']).pipe(
          map((translations) => {
            paginatorIntl.itemsPerPageLabel = translations["PAGINATOR.ITEMS_PER_PAGE"];
            paginatorIntl.getRangeLabel = (page: number, pageSize: number, length: number) => {
              const startIndex = page * pageSize;
              // If the start index exceeds the list length, do not try and fix the end index to the end.
              const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
              return translations["PAGINATOR.RANGE_INTRODUCTION_WORD"] + " " + (startIndex + 1) + "-" + endIndex +  " " + translations["PAGINATOR.RANGE_WORD"] + " " + length.toString()
            };
            return paginatorIntl;
          })
        )
      })
    )
    // return forkJoin([
    //   this.translate.get(['PAGINATOR.ITEMS_PER_PAGE']),
    //   thi
    // ]).pipe(
    //   map(([translation, rangeLabel]) => {
    //     paginatorIntl.itemsPerPageLabel = translation["PAGINATOR.ITEMS_PER_PAGE"];
    //     paginatorIntl.g = (page: number, pageSize: number, length: number) => { return rangeLabel };
    //     return paginatorIntl;
    //   })
    // );
    // return this.translate.get(['PAGINATOR.ITEMS_PER_PAGE']).pipe(
    //   map(translation => {
    //     paginatorIntl.itemsPerPageLabel = translation["PAGINATOR.ITEMS_PER_PAGE"];
    //     return paginatorIntl;
    //   })
    // )
  }

  private getRangeLabel(page: number = 0, pageSize: number = 0, length: number = 0): Observable<string> {
    // if (length === 0 || pageSize === 0) {
    //   return this.translate.instant('RANGE_PAGE_LABEL_1', { length });
    // }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    return this.translate.get("PAGINATOR.RANGE_PAGE", { startIndex: startIndex + 1, endIndex, length }).pipe(
      map((literal: string) => {
        return literal;
      })
    );
  }

}
