import { Router } from '@angular/router';
import {Injectable} from '@angular/core';
import {AuthenticationInterfaceService} from './authentication-interface.service';
import {Observable, of} from 'rxjs';
import {UserRepresentation} from '../resources/user-representation';
import {DecodedToken} from '../resources/decoded-token';

@Injectable()
export class AuthenticationFakeService implements AuthenticationInterfaceService {
  constructor(private _router: Router){

  }
  getAuthenticatedUserRepresentation(): Observable<UserRepresentation> {
    const user: UserRepresentation = new UserRepresentation(new DecodedToken());
    user.firstName = "Maria";
    user.lastName = "Rodriguez";
    return of(user);
  }

  isUserLoggedIn(): Observable<boolean> {
    return of(true);
  }

  logout(): void {
    sessionStorage.clear();
    this._router.navigate(["session-expired"]);
  }
}
