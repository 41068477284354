import {Component} from '@angular/core';
import {AuthenticationService} from '../../services/authentication.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-session-expired',
  templateUrl: 'page-session-expired.component.html',
  styleUrls: ['page-session-expired.component.scss']
})
export class PageSessionExpiredComponent {
  constructor(private _authenticationService: AuthenticationService,
              private _router: Router) {
    this._authenticationService.isUserLoggedIn().subscribe((isUserLoggedIn: boolean) => {
      if (isUserLoggedIn) this._router.navigate([""], { replaceUrl: true })
    });
  }
}
