import { LanguageService } from './language/services/language.service';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DigitalGreenPassportService } from './services/cvdAPI';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'Certificat-Verd-Digital';

  constructor(private _languageService: LanguageService){}

  /// LANGUAGE
  currentLanguageSubscription: Subscription;
  availableLanguages: string[] = [];
  usedLanguage: string;

  ngOnInit() {
    /// LANGUAGE
    this.currentLanguageSubscription = this._languageService.getCurrentLanguage().subscribe((lang: string) => {
      this.usedLanguage = lang;
      this.availableLanguages = this._languageService.getAvailableLanguages().filter(availableLang => availableLang !== this.usedLanguage);
    });

  }

}
