import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivateChild} from '@angular/router';
import {Observable, of} from 'rxjs';
import {AuthenticationInterfaceService} from "../services/authentication-interface.service";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(private _authenticationService: AuthenticationInterfaceService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this._authenticationService.isUserLoggedIn().pipe(map((result: boolean) => {
      if (result) return true;
      else this._authenticationService.logout();
      return false;
    }));
  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this._authenticationService.isUserLoggedIn().pipe(map((result: boolean) => {
      if (result) return true;
      else this._authenticationService.logout();
      return false;
    }));
  }
}
