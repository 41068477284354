import { environment } from './../../../environments/environment.prod';
import {Injectable} from '@angular/core';
import {AuthenticationInterfaceService} from './authentication-interface.service';
import {BehaviorSubject, Observable, of, ReplaySubject} from 'rxjs';
import {UserRepresentation} from '../resources/user-representation';
import {JwtHelperService} from '@auth0/angular-jwt';
import {DecodedToken} from '../resources/decoded-token';
import {RefreshTokensRs} from '../resources/refresh-tokens-rs';
import {HttpClient} from '@angular/common/http';
import {catchError, delay, map} from 'rxjs/operators';
import {RefreshTokensOperationStatusEnum} from '../resources/refresh-tokens-operation-status-enum';
import {Router} from '@angular/router';

const _jwtHelper = new JwtHelperService();

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService implements AuthenticationInterfaceService {
  constructor(private _http: HttpClient,
              private _router: Router) {
    const existingToken = this.accessToken;
    if (existingToken) this.accessToken = existingToken;
  }

  private userRepresentation: ReplaySubject<UserRepresentation> = new ReplaySubject<UserRepresentation>(1);
  private refreshOperation: BehaviorSubject<RefreshTokensOperationStatusEnum> = new BehaviorSubject<RefreshTokensOperationStatusEnum>(RefreshTokensOperationStatusEnum.READY);

  get accessToken(): string {
    return sessionStorage.getItem('accessToken');
  }

  set accessToken(token: string) {
    if (token == "undefined") token = null;
    sessionStorage.setItem('accessToken', token);
    this.userRepresentation.next(this.parseToken(token));
  }

  get refreshToken(): string {
    return sessionStorage.getItem('refreshToken');
  }

  set refreshToken(token: string) {
    if (token == "undefined") token = null;
    sessionStorage.setItem('refreshToken', token);
  }

  refreshTokens(): Observable<RefreshTokensOperationStatusEnum> {
    if (this.refreshOperation.value === RefreshTokensOperationStatusEnum.READY) {
      this.refreshOperation.next(RefreshTokensOperationStatusEnum.LOADING);
      let formData = new FormData();
      formData.append('token', this.refreshToken);
      this._http.post<RefreshTokensRs>(environment.securityApiUrl + "/refresh", formData).pipe(
        map((tokens: RefreshTokensRs) => {
          this.accessToken = tokens.result.access_token;
          this.refreshToken = tokens.result.refresh_token;
          this.refreshOperation.next(RefreshTokensOperationStatusEnum.SUCCEED);
          return null;
        }),
        catchError(() => {
          this.refreshOperation.next(RefreshTokensOperationStatusEnum.FAILED);
          return null;
        }),
        delay(1000)
      ).subscribe(() => {
        this.refreshOperation.next(RefreshTokensOperationStatusEnum.READY);
      });
    }

    return this.refreshOperation.asObservable();
  }

  getAuthenticatedUserRepresentation(): Observable<UserRepresentation> {
    return this.userRepresentation.asObservable();
  }

  isUserLoggedIn(): Observable<boolean> {
    if (this.refreshToken == "undefined") return of(false);
    if((!this.accessToken || _jwtHelper.isTokenExpired(this.accessToken)) &&
      (!this.refreshToken || _jwtHelper.isTokenExpired(this.refreshToken))) {
      return of(false);
    }
    else return of(true);
  }

  logout(): void {
    sessionStorage.clear();
    this._router.navigate(["session-expired"]);
  }

  private parseToken(token: string): UserRepresentation {
    if (!token) return null;
    return new UserRepresentation(_jwtHelper.decodeToken(this.accessToken) as DecodedToken);
  }
}
