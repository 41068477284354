import {NgModule} from '@angular/core';
import {AuthenticationInterfaceService} from './services/authentication-interface.service';
import {AuthenticationFakeService} from './services/authentication-fake.service';
import {environment} from '../../environments/environment';
import {AuthenticationService} from './services/authentication.service';
import {HttpClient} from '@angular/common/http';
import {AuthGuard} from "./guards/auth.guard";
import {Router} from '@angular/router';
import {PageSessionExpiredComponent} from './components/page-session-expired.component/page-session-expired.component';
import {SharedModule} from '../shared/shared.module';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  declarations: [
    PageSessionExpiredComponent
  ],
  imports: [
    SharedModule,
    TranslateModule.forChild()
  ],
  exports: [
    PageSessionExpiredComponent
  ],
  providers: [
    {
      provide: AuthenticationInterfaceService,
      useFactory: (_http: HttpClient, _router: Router) => {
        if (environment.demo) {
          return new AuthenticationFakeService(_router);
        } else {
          return new AuthenticationFakeService(_router);

///          return new AuthenticationService(_http, _router);
        }
      },
      deps: [HttpClient, Router]
    },
    AuthGuard
  ]
})
export class AuthenticationModule { }
