import {Observable} from 'rxjs';
import {UserRepresentation} from '../resources/user-representation';
import {Injectable} from '@angular/core';

@Injectable()
export abstract class AuthenticationInterfaceService {
  abstract getAuthenticatedUserRepresentation(): Observable<UserRepresentation>;
  abstract isUserLoggedIn(): Observable<boolean>;
  abstract logout(): void;
}
