/**
 * Digital Green Passport
 * Digital Green Passport, the error handler is implement using   The IETF devised RFC 7807 effor, which creates a generalized error-handling schema. https://tools.ietf.org/html/rfc7807
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AuditRequest } from './auditRequest';


/**
 * This model is used when submitting a request for a certificate
 */
export interface CertificateRequest { 
    /**
     * audit information
     */
    audit: AuditRequest;
    /**
     * type of de certificate requested
     */
    certificateType: string;
    /**
     * código de identificación personal CatSalud
     */
    cip: string;
    /**
     * documento nacional de identidad
     */
    dni?: string;
}
export namespace CertificateRequest {
    export type CertificateTypeEnum = 'RECOVERY' | 'TEST' | 'VACCINE';
    export const CertificateTypeEnum = {
        RECOVERY: 'RECOVERY' as CertificateTypeEnum,
        TEST: 'TEST' as CertificateTypeEnum,
        VACCINE: 'VACCINE' as CertificateTypeEnum
    };
}
